
const closeElementQuerySelector = '.country-select-close';
const countrySelectContainer = '.country-select-container';
const html = document.documentElement;

const init = () => {
  window.addEventListener('load', () => {
    const closeElement = document.querySelector(closeElementQuerySelector);
    if (!closeElement) return;

    closeElement.addEventListener('click', () => {
      const container = document.querySelector(countrySelectContainer);
      if (!container) return;
      container.classList.remove('display-flex');
      html.classList.remove('html-no-scroll');
    })
  });
}

const showCountrySelect = () => {
  const container = document.querySelector(countrySelectContainer);
  if (!container) return;
  container.classList.add('display-flex');
  html.classList.add('html-no-scroll');
}

export const CountrySelect = {
  init,
  showCountrySelect,
}
