
export const hasTruncatedTextFromLineClamp = (element: Element) => {
  return element.scrollHeight > element.clientHeight;
};

export const AMOUNT_OF_NEWSPAPERS_AND_MAGAZINES = '7000';
export const ACCOUNT_CHANGE_EVENT_NAME = "account-change";

export const sendAccountChangeEvent = () => {
  const event = new CustomEvent(ACCOUNT_CHANGE_EVENT_NAME, {});
  window.dispatchEvent(event);
}


export type operating_system = 'windows phone' | 'android' | 'ios' | null;
export const getMobileOperatingSystem: () => operating_system = () => {
  const w = window as any;
  const userAgent = w.navigator.userAgent;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "windows phone";
  }

  if (/android/i.test(userAgent)) {
    return "android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !w.MSStream) {
    return "ios";
  }

  return null;
}
