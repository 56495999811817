interface props {
  showCountrySelect: Function
}

const init = (props: props) => {
  const flagClickElement = document.querySelector('.readly-footer-flag-container');
  if (flagClickElement) {
    flagClickElement.addEventListener('click', () => {
      props.showCountrySelect();
    });
  }
}

export const ReadlyFooter = {
  init,
};
