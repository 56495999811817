import Cookies from 'js-cookie'

export {};

declare global {
  interface Window { 
    nav_click_tracking: (src: String, url: String) => void
    search_result_onclick_tracking: (event: JSON) => void
    delayedDataLayerPush: (event: JSON) => void
    initalDataLayerData: object[]
  }
}

//// Please use this going forward so we don't create a new function for each special use ////
// The reason for needing this is when we have datalayer pushes we want to do on links/buttons that
// send the user to a new page. It is not always GTM has time to pick up the events. With this we push
// the events on the page we end up on instead.

(() => {
  const localStorageKey = 'delayedDataLayerPush';
  window.delayedDataLayerPush = (event: JSON) => {
    // const storage = localStorage.getItem(localStorageKey);
    // const json = JSON.parse(storage);
    // if (json && Array.isArray(json)) {
    //   json.push(event)
    //   localStorage.setItem(localStorageKey, JSON.stringify(storage));
    // } else {
    //   localStorage.setItem(localStorageKey, JSON.stringify([event]));
    // }

    window.dataLayer.push(event);
  }

  const delayed = localStorage.getItem(localStorageKey);
  if (delayed) {
    const json = JSON.parse(delayed);
    if (!Array.isArray(json)) return;

    for (const item of json) {
      window.dataLayer.push(item)
    }

    localStorage.removeItem(localStorageKey);
  }
})()

////////////////////////////////


// Below should be refactored to use the above
window.nav_click_tracking = window.nav_click_tracking || ((src, url) => {
  Cookies.set("nav_click_tracking", `${src}||${url}`, { domain: `.${location.hostname}`, path: "/" })
});

window.search_result_onclick_tracking = window.search_result_onclick_tracking || ((event) => {
  Cookies.set("search_result_onclick_tracking", `${event}`, { domain: `.${location.hostname}`, path: "/" })
});

(function() {
  try {
    const nav_click_tracking = Cookies.get("nav_click_tracking");
    if (nav_click_tracking) {
      const [src, url] = nav_click_tracking.split("||")
      
      if (url.startsWith(location.pathname)) {
        Cookies.remove("nav_click_tracking", { domain: `.${location.hostname}`, path: "/" })
        window.initalDataLayerData.forEach(entry => {
          if (entry["event"] && entry["event"] == "view_item") {
            entry["ecommerce"]["items"].forEach(item => {
                item["item_list_name"] = src
            })
          }
        })
      }
    }
  } catch (e) {
    console.warn(e)
  }
  
  if (window.dataLayer && window.initalDataLayerData) {
    for (const entry of window.initalDataLayerData) {
      window.dataLayer.push(entry);
    }
  }
})();

(function () {
  try {
    const search_result_onclick_tracking = Cookies.get("search_result_onclick_tracking");
    if (search_result_onclick_tracking) {
      const event = JSON.parse(decodeURIComponent(window.atob(search_result_onclick_tracking)));
      
      Cookies.remove("search_result_onclick_tracking", { domain: `.${location.hostname}`, path: "/" });
      window.dataLayer.push(event);
    }
  } catch (e) {
    console.warn(e);
  }
})();

