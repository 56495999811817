import { AccountInfo, getAccountInfo } from "../../../../javascript/account-api";
import { ACCOUNT_CHANGE_EVENT_NAME } from "../../../../javascript/utils";

interface props {
  showCountrySelect: Function
}

const init = (props: props) => {
  window.addEventListener("load", () => {
    // Handle signout link click
    const signoutLinkElement = document.querySelector('.readly-signout-link') as HTMLAnchorElement;
    if (!signoutLinkElement) return;

    signoutLinkElement.addEventListener('click', (event) => {
      event.preventDefault();
      const href = signoutLinkElement.href;
      const method = signoutLinkElement.dataset.method;
      const csrfToken = document.querySelector('meta[name=csrf-token]').attributes.getNamedItem("content").value;
      const csrfParam = document.querySelector('meta[name=csrf-param]').attributes.getNamedItem("content").value;

      const form = `
                  <form id="signOutForm" method="post" action="${href}">
                      <input name="_method" value="${method}" type="hidden" />
                      <input name="${csrfParam}" value="${csrfToken}" type="hidden" />
                  </form>
              `;

      document.body.innerHTML += form;
      (document.getElementById('signOutForm') as HTMLFormElement).submit()
    });
  });

  // Dropdown open
  const dropdownMenu = document.querySelector('.readly-header-menu-dropdown-open-link ~ .readly-header-menu-dropdown');
  if (dropdownMenu) {
    window.addEventListener("load", () => {
      // Add event listener for all dropdown in the menu
      document.querySelectorAll('.readly-header-menu-dropdown-open-link').forEach((element) => {
        element.addEventListener('click', () => {
          if (dropdownMenu) {
            dropdownMenu.classList.toggle('display-flex')
          }
        });
      });
    });
  
    // Dropdown close on clicking outside
    window.addEventListener("click", (e: MouseEvent) => {
      const targetNode = e.target as Node;
      const dropdownMenuLink = document.querySelector('.readly-header-menu-dropdown-open-link');
      const dropdownLink = document.querySelector('.readly-header-dropdown-link');
      
      // Don't close dropdown while clicking on padded part of it.
      if ((
          !dropdownMenu.contains(targetNode) && 
          !dropdownMenuLink.contains(targetNode) && 
          dropdownMenu.classList.contains('display-flex')
        ) || 
        // Close it, when link is clicked.
        dropdownLink.contains((targetNode))
      ) {
        dropdownMenu.classList.remove('display-flex')
      }
    })
  }

  
  window.addEventListener("load", () => {
    // Handle mobile menu expand/close
    const mobileMenuEl = document.querySelector('.readly-header-mobile-menu-container') as HTMLDivElement;
    const html = document.documentElement;
    if (!mobileMenuEl) return;

    const mobileMenuExpandEl = document.querySelector('.readly-header-mobile-expand');
    if (mobileMenuExpandEl) {
      mobileMenuExpandEl.addEventListener('click', () => {
        mobileMenuEl.classList.toggle('display-block')
        html.classList.add('html-no-scroll');
      });
    }

    const mobileMenuCloseEl = document.querySelector('.readly-header-mobile-close-icon');
    if (mobileMenuCloseEl) {
      mobileMenuCloseEl.addEventListener('click', () => {
        mobileMenuEl.classList.toggle('display-block')
        html.classList.remove('html-no-scroll');
      })
    }
  })

  window.addEventListener("load", () => {
    const countrySelectorExpandElements = document.querySelectorAll('.readly-header-flag-expand');
    for (const element of countrySelectorExpandElements) {
      element.addEventListener('click', () => {
        props.showCountrySelect();
      });
    }
  })

  const setAccountState = () => {
    getAccountInfo().then(accountInfo => {
      Header.registerAccountInfo(accountInfo);
    });
  }
  setAccountState();
  window.addEventListener(ACCOUNT_CHANGE_EVENT_NAME, () => setAccountState());
}

const registerAccountInfo = (accountInfo: AccountInfo) => {
  if (accountInfo) {
    const loaderElement = document.querySelector('.readly-header-account-container .readly-loading') as HTMLDivElement;
    if (loaderElement) loaderElement.remove();

    const loggedInElements = document.querySelectorAll('.readly-header-account-logged-in-container');
    const loggedOutElements = document.querySelectorAll('.readly-header-account-logged-out-container');
    const allElements = [...loggedInElements, ...loggedOutElements];

    for (const element of allElements) {
      (element as HTMLDivElement).classList.remove('display-flex');
    }

    if (accountInfo.logged_in) {
      for (const element of loggedInElements) {
        (element as HTMLDivElement).classList.add('display-flex');
      }
    } else {
      const loggedOutElements = document.querySelectorAll('.readly-header-account-logged-out-container');
      for (const element of loggedOutElements) {
        (element as HTMLDivElement).classList.add('display-flex');
      }
    }
  }
}

export const Header = {
  init,
  registerAccountInfo,
}
